<template lang="pug">
inventoryMap
</template>

<script>
import inventoryMap from '@/components/inventory/map';
import {mapActions, mapMutations} from 'vuex';
import {CLEAR_INVENTORY_BY_ID, GET_INVENTORY_BY_ID} from '@/store/const/inventory';

export default {
	name: 'InventoryId',
	components: {
		inventoryMap,
	},
	async mounted() {
		let data = {
			id: this.$route.params.id,
		};
		await this.GET_INVENTORY_BY_ID(data);
	},
	beforeDestroy() {
		this.CLEAR_INVENTORY_BY_ID()
	},
	methods: {
		...mapActions('Inventory', [GET_INVENTORY_BY_ID]),
		...mapMutations('Inventory', [CLEAR_INVENTORY_BY_ID])
	}
	
};
</script>

<style scoped></style>
