import { mapGetters } from "vuex";

const formatRquestStatsData = {
  computed: {
    ...mapGetters("Map", ["getCities", "getCurrentCity"]),
  },
  methods: {
    formatData(campaign, markers) {
      // возвращается дата с со смещением назад
      const formatDate = (timestamp, monthT) => {
        let campaignPeriodDate = new Date(timestamp);
        const now = new Date();
        const monthDistance =
          Math.abs(campaignPeriodDate.getMonth() - now.getMonth()) + monthT;
        campaignPeriodDate = new Date(
          campaignPeriodDate.setMonth(
            campaignPeriodDate.getMonth() - monthDistance
          )
        );

        return campaignPeriodDate
          .toLocaleDateString()
          .split(".")
          .reverse()
          .join("-");
      };
      const gender =
        campaign.audience.sex.length === 2 || campaign.audience.sex.length === 0
          ? "all"
          : campaign.audience.sex[0];
      const income = [].concat(campaign.audience.income).sort().join("") || "abc";
      const cityRuName = this.getCities.find(
        (city) => city.id === this.getCurrentCity
      );
      if (!campaign.periodFrom) {
        let calculatedData = this.calculateDate();
        campaign.periodFrom = calculatedData[0];
        campaign.periodTo = calculatedData[1];
      }
      return {
        periodFrom: formatDate(campaign.periodFrom, 4),
        periodTo: formatDate(campaign.periodTo, 3),
        surfaces:
          markers.length > 0
            ? markers.map((marker) => {
                return Number(marker.id || marker);
              })
            : [0],
        city: cityRuName ? cityRuName.name : "",
        audience: {
          age_from: Number(campaign.audience.ageFrom) || 18,
          age_to: Number(campaign.audience.ageTo) || 99,
          gender,
          income: income,
        },
      };
    },
    calculateDate() {
      let date = [
        new Date(new Date().setDate(new Date().getDate() + 7)),
        new Date(),
      ];

      let dateFrom = date[0],
        dd = dateFrom.getDate(),
        mm = dateFrom.getMonth() + 1,
        yy = dateFrom.getFullYear();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      if (yy < 10) yy = "0" + yy;

      let dateTo = date[1],
        dd2 = dateTo.getDate(),
        mm2 = dateTo.getMonth() + 1,
        yy2 = dateTo.getFullYear();
      if (dd2 < 10) dd2 = "0" + dd2;
      if (mm2 < 10) mm2 = "0" + mm2;
      if (yy2 < 10) yy2 = "0" + yy2;
      return [`${yy}-${mm}-${dd}`, `${yy2}-${mm2}-${dd2}`];
    },
    // @see code this https://stackoverflow.com/questions/16637051/adding-space-between-numbers
    formatNumber(number) {
      if (number === undefined || number === null) {
        return 0;
      }
      let num = +number;
      let parts = parseFloat(num.toFixed(2)).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
  },
};
export {formatRquestStatsData};
