<template lang="pug">
  v-btn(elevation='0', color='accent', min-width='180px', @click='selectCsv') {{ csvBtnText }}
    input.d-none(ref='uploader', type='file', accept='text/csv', @change='uploadCsv')
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import * as csv from 'csvtojson';
import {ADD_CAMPAIGN_SURFACE} from '@/store/const/campaign';
import {GET_UNITS} from '@/store/const/map';

export default {
  name: 'UploadCSVList',
  data() {
    return {
      isSelecting: false,
      filename: null
    };
  },

  computed: {
    ...mapGetters('Campaign', ['getSelectedSurfaces']),
    ...mapGetters('Map', ['getUnits']),
    csvBtnText() {
      return this.filename ? this.filename : 'Загрузить список (csv)';
    },
  },
  methods: {
    ...mapMutations('Campaign', [ADD_CAMPAIGN_SURFACE]),
    ...mapActions('Map', [GET_UNITS]),
    selectCsv() {
      this.isSelecting = true;
      this.$refs.uploader.click();
    },
    uploadCsv(e) {
      if (e.target.files[0]) {
        this.filename = e.target.files[0].name;
        if (e.target.files[0].name.toUpperCase().split('.')[e.target.files[0].name.split('.').length - 1] !== 'CSV') {
          this.$notify({
            type: 'error',
            title: 'Загрузка файла',
            text: 'Загрузите файл формата CSV',
          });
          return;
        }
        let reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onload = () => {
          csv({delimiter: ';'})
          .fromString(reader.result)
          .then(async (json) => {
            let data = json.filter((i) =>
              i.id === i['id'] ||
              i.gid === i['gid'] ||
              i.longitude === i['longitude'] && i.latitude === i['latitude']
            );
            if (data.length < 1) {
              this.$notify({
                type: 'error',
                title: 'Загрузка файла',
                text: 'Не удалось загрузить список поверхностей из выбранного файла',
              });
              return;
            }
            await this.GET_UNITS({session_type: 'account'})
            this.getUnits.filter(item => {
              if (data.find(val =>
                (val.id && val.id === item.id) ||
                (val.gid && val.gid === item.gid) ||
                (val.longitude && val.longitude.replace(',', '.') === item.longitude.toString()) &&
                (val.latitude && val.latitude.replace(',', '.') === item.latitude.toString())
              )) {
                this.ADD_CAMPAIGN_SURFACE(item)
              }
            })
            this.$emit('updateSurfaces')
          });
        };
        reader.onerror = () => {
          this.$notify({
            type: 'error',
            title: 'Загрузка файла',
            text: 'Не удалось загрузить список поверхностей из выбранного файла',
          });
        };
        this.filename = null;
      } else {
        this.$emit('getUnits', []);
      }
    },
  },
};
</script>
