<template lang="pug">
  .blocks.pl-3(:class="{'blocks_list': isList}")
    .gender(:class="{'gender_list': isList}")
      .filter-label Пол
      v-row
        v-col
          v-checkbox(
            v-model='campaign.audience.sex',
            key='male',
            label='Мужской',
            value='male',
            color='accent',
            hide-details
          )
        v-col
          v-checkbox(
            v-model='campaign.audience.sex',
            key='female',
            label='Женский',
            value='female',
            color='accent',
            hide-details
          )
    .age(:class="{'age_list': isList}")
      .filter-label Возраст
      v-row
        v-col
          v-text-field.age-input(
            v-model='campaign.audience.ageFrom',
            dense,
            hide-details='auto',
            outlined,
            label='мин. 18',
            type='number',
            :min='18',
            :max='99'
          )
        v-col.d-flex.align-center
          div -
        v-col
          v-text-field.age-input(
            v-model='campaign.audience.ageTo',
            dense,
            hide-details='auto',
            outlined,
            label='макс. 99',
            type='number',
            :min='18',
            :max='99'
          )
    .income(:class="{'d-flex': isList, 'flex-column': !isList}")
      .filter-label Уровень дохода
      v-row(:class="{'pb-2': isList}")
        v-col.mt-0.pt-0.pr-0
          v-checkbox(v-model='campaign.audience.income', key='A', label='A', value='a', color='accent', hide-details)
        v-col.mt-0.pt-0.pr-0
          v-checkbox(v-model='campaign.audience.income', key='B', label='B', value='b', color='accent', hide-details)
        v-col.mt-0.pt-0.pr-0.mr-0
          v-checkbox(v-model='campaign.audience.income', key='C', label='C', value='c', color='accent', hide-details)
    .rating.el-row--flex(:class="{'raiting_list': isList}")
      span.filter-label.demonstration Рейтинг
      v-range-slider.rating-slider(
        range,
        color='accent',
        hide-details,
        :min='ratingExtremum.min',
        :max='ratingExtremum.max',
        :value='range',
        :disabled='isDisabled',
        @input='setRating'
      )
        template(v-slot:prepend)
          span.pt-6 {{ getSurfacesStats.length ? ratingExtremum.min : 'мин' }}
        template(v-slot:append)
          span.pt-6 {{ getSurfacesStats.length ? ratingExtremum.max : 'макс' }}
    v-btn.border_button(
      small,
      outlined,
      @click='setFilter',
      :class="{'mr-5': isList, 'mt-5': !isList}"
      :loading="isLoading"
      :disabled="isLoading"
    ) Обозначить поверхности
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {formatRquestStatsData} from '@/mixins/date_mixin';
import {GET_SURFACE_STATS, SET_RATING} from '@/store/const/map';
import {SET_CURRENT_CAMPAIGN} from '@/store/const/campaign';
import loading from '@/mixins/loading_mixin';

export default {
  name: 'Filters',
  mixins: [formatRquestStatsData, loading],
  props: {
    currentCity: {
      type: String,
    },
  },
  data() {
    return {
      campaign: {},
      rating: {
        min: 0,
        max: 0
      },
    };
  },
  created() {
    this.campaign = this.getCampaign;
  },
  watch: {
    getCampaign: {
      handler() {
        this.campaign = this.getCampaign;
      },
      deep: true
    },
    campaign: {
      handler() {
        this.SET_CURRENT_CAMPAIGN(this.campaign)
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('Map', ['getSurfacesStats', 'getUnits', 'getCreateMode', 'getRating', 'getCurrentCity']),
    ...mapGetters('Campaign', ['getCampaign']),
    isList() {
      return this.getCreateMode === 'list'
    },
    range() {
      if (!this.ratingExtremum.isEvent) {
        return [this.getRating.min, this.getRating.max]
      }
      return [this.ratingExtremum.min, this.ratingExtremum.max]
    },
    ratingExtremum() {
      let ratings = [0, 300]
      let isEvent = false
      let surfacesStats = this.getSurfacesStats.filter(stats => stats)
      if (surfacesStats && surfacesStats.length > 0) {
        isEvent = true
        ratings = surfacesStats.map((surface) => surface.rating);
      }
      return {
        max: Math.max(...ratings).toFixed(1),
        min: 0,
        isEvent
      };
    },
    isDisabled() {
      if (this.getSurfacesStats && this.getSurfacesStats.length < 1) {
        return true
      }
      return false
    }
  },
  mounted() {
    this.setLoadingActions()
  },
  methods: {
    ...mapActions('Map', [GET_SURFACE_STATS]),
    ...mapMutations('Campaign', [SET_CURRENT_CAMPAIGN]),
    ...mapMutations('Map', [SET_RATING]),
    setLoadingActions() {
      this.actions = [
        GET_SURFACE_STATS
      ]
    },
    setRating(value) {
      this.SET_RATING({
        min: value[0],
        max: value[1]
      })
      this.$emit('updateRating')
    },
    debounce: function (callback, time) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        callback();
      }, time);
    },
    setFilter() {
      this.debounce(async () => {
        try {
          const data = this.formatData(this.campaign, this.getUnits.filter((surface) => surface.city === this.getCurrentCity));
          if (data.periodFrom !== 'Invalid Date') {
            data.periodFrom = this.$moment().subtract(7, 'days').format('YYYY-MM-DD')
            data.periodTo = this.$moment().subtract(7, 'days').format('YYYY-MM-DD')
            data.city = this.getCurrentCity;
            await this.GET_SURFACE_STATS(data);
            if (!this.getSurfacesStats.length) {
              this.$notify({
                type: 'error',
                title: 'Ошибка',
                text: 'Нет данных',
              });
            }
          }
        } catch (e) {
          this.$notify({
            type: 'error',
            title: '',
            text:
              'Ошибка при высчитывании рейтинга \n"' +
              (e.error && e.error.message ? e.error.message : e) +
              '"',
          });
        }
      }, 800);
    },
  },
};
</script>

<style lang="scss">
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px;
  align-items: flex-start;
  padding: 20px 0 0;
  flex-wrap: wrap;

  & > div {
    margin: 10px 30px 10px 0;
  }

  & > .raiting_list {
    display: flex;
  }
}

.blocks_list {
  padding-top: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.age {
  align-items: center;
}

.age_list {
  display: flex;
}

.gender {
  display: flex;
  flex-direction: column;

  & .v-input {
    margin: 0;
    padding: 0;
  }
}

.gender_list {
  display: flex;
  flex-direction: row;
}

.age-input {
  width: 70px;

  &-line {
    width: 13px;
    border: 1px solid #dcdfe7;
    margin: 0 7px;
  }

  & .v-input__slot {
    padding: 0 8px !important;
  }
}

.filter-label {
  font-size: 14px;
  margin: auto 15px auto 0;
}

.radios {
  & .v-input {
    margin: 0;
    padding: 0;
  }
}

.income {
  display: flex;
  flex-wrap: wrap;

  & label {
    margin-right: 15px;
  }
}

.rating-slider {
  min-width: 100px;
  width: 230px;
  position: relative;

  & .v-slider__thumb-label {
    background-color: transparent !important;
    color: #737373;
  }
}

.border_button {
  border-color: #4A55B0;
}
</style>
