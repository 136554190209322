<template lang="pug">
  .surfaces
    table
      thead
        tr
          td.d-flex.justify-center.align-center
            v-checkbox.pt-4.checkbox_margin(
              v-model='selectAll'
              color='#4A55B0'
              off-icon="mdi-checkbox-blank-circle-outline"
              on-icon="mdi-check-circle-outline"
              dense
              @click="selectAllSurfaces"
            )
            .action.pointer.mr-2(@click="batchDeleteSurfaces") Очистить
          template(v-for='header in getHeaders')
            td.header_cell.pointer(@click="sortColumn(header.value)") {{header.label}}
              span(v-if='showSort === header.value')
                v-icon(v-if='sortDirection === "asc"', small, color='#FAFAFA') mdi-arrow-up
                v-icon(v-else, small, color='#FAFAFA') mdi-arrow-down
      tbody.table_body
        template(v-for="surface in sortedSurface")
          tr.table_row(:key="surface.id")
            td
              .row-action(v-if="isSelected(surface)")
                v-checkbox.pt-4.checkbox_margin(
                  v-model='selected'
                  :value='surface.id'
                  color='#4A55B0'
                  off-icon="mdi-checkbox-blank-circle-outline"
                  on-icon="mdi-check-circle-outline"
                  dense
                )
                button.delete_btn.mt-1.mr-3(@click="deleteSurface(surface)")
                  v-icon(color='#4A55B0' size='15') mdi-trash-can-outline
              div(v-else)
                v-icon.pointer(color='#4A55B0' @click="addSurface(surface)") mdi-plus-circle-outline
            td.table_cell(v-for="header in getHeaders" :class="{'rating_below': isRatingBelow(surface)}")
              span.d-flex.justify-space-between.align-center(v-if="header.value === 'gid' && needTooltip(surface.id)" ) {{ surface.gid }}
                tooltip(:text='messageTooltip(surface.id)')
                  .pointer.isCircleRed
              span(v-else-if="header.value === 'venue'") {{ getVenue(surface[header.value]) }}
              span(v-else-if='header.value === "publisherId"') {{publisherName(surface[header.value])}}
              span(v-else-if="header.value === 'description'") {{ surface.hasOwnProperty('description') ? surface.description : surface.address }}
              span(v-else-if="header.value === 'avgBidFloor'") {{ getBidFloor(surface.id) }}
              span(v-else-if="header.value === 'size'") {{ getSize(surface.id) }}
              span(v-else) {{surface[header.value]}}
    v-pagination.mt-2(v-if="pageCount > 1" v-model="page" :length="pageCount" :total-visible="7")
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import Tooltip from '@/components/tooltip';
import {ADD_CAMPAIGN_SURFACE, REMOVE_CAMPAIGN_SURFACE} from '@/store/const/campaign';
import {GET_FORECAST} from '@/store/const/report';
import {formatRquestStatsData} from '@/mixins/date_mixin';

export default {
  name: 'surfaces',
  components: {Tooltip},
  mixins: [formatRquestStatsData],
  data() {
    return {
      page: 1,
      per_page: 10,
      selectAll: false,
      headers: [
        {label: 'GID (идентификатор)', value: 'gid'},
        {label: 'Адрес', value: 'description'},
        {label: 'Поставщик', value: 'publisherId'},
        {label: 'Сторона', value: 'side'},
        {label: 'Формат', value: 'venue'},
        {label: 'Тип поверхности', value: 'type'},
        {label: 'Город', value: 'city'},
        {label: 'Стоимость выхода', value: 'avgBidFloor'},
        {label: 'Рейтинг', value: 'rating'},
        {label: 'Объем показов', value: 'size'}
      ],
      selected: [],
      sortBy: 'name',
      sortDirection: 'asc',
      isActiveCircle: {},
      isDeletedCircle: {},
      showSort: '',
      timeout: null
    }
  },
  watch: {
    getSelectedSurfaces(surfaces) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.getStrategiesMode === 'minCPM' || this.getCampaign.bidStrategy === 'minCPM') {
        this.timeout = setTimeout(async () => {
          let from = this.$moment.utc().subtract(7, 'days').startOf('day').valueOf()
          let to = this.$moment.utc().endOf('day').valueOf()
          const ids = surfaces.map((marker) => marker.id);
          let params = {
            filter: [
              {
                field: 'surface_id',
                operation: '=',
                values: ids,
              },
            ],
            from,
            to,
            group: ['surface_id'],
            limit: null,
          };
          await this.GET_FORECAST(params)
        }, 1000)
      }
    },
    selected(value) {
      if (value.length < this.getSelectedSurfaces.length) {
        this.selectAll = false
      }
    }
  },
  computed: {
    ...mapGetters('Campaign', ['getSelectedSurfaces', 'getStrategiesMode', 'getCampaign']),
    ...mapGetters('Report', ['getAnalytics']),
    ...mapGetters('Map', ['getFilteredUnits', 'getCreateMode', 'getTableMode', 'getFormats', 'getRating', 'getSurfacesStats', 'getPublishers']),
    getHeaders() {
      if (this.getStrategiesMode === 'minCPM' || this.getCampaign.bidStrategy === 'minCPM') {
        return this.headers
      }
      return this.headers.filter(item => item.value !== 'avgBidFloor')
    },
    getSurfaces() {
      let start = (this.page - 1) * this.per_page
      if (this.getTableMode === 'all' && this.getCreateMode === 'list') {
        return this.getFilteredUnits.slice(start, start + this.per_page)
      }
      let selectedSurfaces = this.getSelectedSurfaces.map(surface => {
        surface.rating = ''
        const surface_stat = this.getSurfacesStats.find(stat => stat.surfaceId == surface.id)
        if (surface_stat) {
          surface.rating = surface_stat.rating.toFixed(1)
        }
        return surface
      })
      const data = this.getFilteredUnits.filter(item => this.getSelectedSurfaces.find(val => val.gid === item.gid))
      data.map(item => {
        if (item.isDeleted === true) {
          return this.isDeletedCircle[item.id] = true
        }
        if (item.isActive === false) {
          return this.isActiveCircle[item.id] = false
        }
      })
      return selectedSurfaces.slice(start, start + this.per_page)
    },
    sortedSurface() {
      return this.getSurfaces.sort((a, b) => {
        let modifier = 1;
        let sortBy = this.sortBy
        if (!a.hasOwnProperty(sortBy) && sortBy === 'description' && a.hasOwnProperty('address')) {
          sortBy = 'address'
        }
        if (this.sortDirection === 'desc') {
          modifier = -1
        }
        if (a[sortBy] < b[sortBy]) {
          return -1 * modifier
        }
        if (a[sortBy] > b[sortBy]) {
          return 1 * modifier
        }
        return 0;
      })
    },
    pageCount() {
      let units = this.getSelectedSurfaces
      if (this.getTableMode === 'all' && this.getCreateMode === 'list') {
        units = this.getFilteredUnits
      }
      return Math.ceil(units.length / this.per_page)
    },
  },
  methods: {
    ...mapMutations('Campaign', [ADD_CAMPAIGN_SURFACE, REMOVE_CAMPAIGN_SURFACE]),
    ...mapActions('Report', [GET_FORECAST]),
    selectAllSurfaces() {
      if (!this.selectAll) {
        this.selected = [];
        return
      }
      this.getSelectedSurfaces.map(surface => {
        if (!this.selected.includes(surface.id)) {
          this.selected.push(surface.id)
        }
      })
    },
    getVenue(value) {
      const venue = this.getFormats.filter(format => format.id === value)
      return Object(venue.pop()).name
    },
    isRatingBelow(surface) {
      if (!this.getRating.min && !this.getRating.max) {
        return false
      }
      if (!surface.hasOwnProperty('rating')) {
        return false
      }
      return surface.rating < this.getRating.min || surface.rating > this.getRating.max
    },
    isSelected(surface) {
      return this.getSelectedSurfaces.findIndex(selected_surface => selected_surface.id === surface.id) !== -1
    },
    addSurface(surface) {
      this.ADD_CAMPAIGN_SURFACE(surface);
    },
    deleteSurface(surface) {
      this.REMOVE_CAMPAIGN_SURFACE(surface)
      this.$emit('deleted')
    },
    batchDeleteSurfaces() {
      this.selected.map(surface => this.REMOVE_CAMPAIGN_SURFACE({id: surface}))
      this.$emit('deleted')
      this.selected = []
      this.selectAll = false
    },
    sortColumn(key) {
      if (key === this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortBy = key;
      this.showSort = key
    },
    needTooltip(id) {
      return this.isActiveCircle[id] === false || this.isDeletedCircle[id] === true;
    },
    messageTooltip(id) {
      if (this.isActiveCircle[id] === false) {
        return 'временно не активна'
      } else if (this.isDeletedCircle[id] === true) {
        return 'долго не активна'
      }
    },
    publisherName(item) {
      const publisher = this.getPublishers.find(a => a.id === item)
      return publisher ? publisher.name : item
    },
    getBidFloor(item) {
      if (this.getStrategiesMode === 'minCPM' || this.getCampaign.bidStrategy === 'minCPM') {
        const bidFloor = this.getAnalytics.find(a => a.surface_id === item)
        return bidFloor ? (bidFloor.avgBidFloor + (bidFloor.avgBidFloor / 100) * this.getCampaign.raisingCof).toFixed(2) : ''
      }
    },
    getSize(item) {
      if (this.getStrategiesMode === 'minCPM' || this.getCampaign.bidStrategy === 'minCPM') {
        const size = this.getAnalytics.find(a => a.surface_id === item)
        return size ? this.formatNumber(size.count) : ''
      }
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.header_cell, .table_cell {
  padding: 10px;
  text-align: justify;
  border: 1px solid #DCDFE7;
  position: relative;
}

.header_cell {
  background: #4A55B0;
  color: #fff;
}

.action {
  color: #4A55B0;
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
}

.row-action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.delete_btn {
  width: 20px;
  height: 20px;
  border: 1px solid #4A55B0;
  border-radius: 50%;
  color: #4A55B0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox_margin {
  margin-top: 5px;
}

.rating_below {
  color: #D32F2F;
}

.isCircleRed {
  display: flex;
}

.isCircleRed::after {
  content: '';
  width: 12px;
  height: 12px;
  display: block;
  border: 2px solid red;
  margin: 0;
  background: red;
  border-radius: 50%;
}
</style>
