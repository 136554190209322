<template lang="pug">
  div
    .map-block.mb-0.pb-0(:class="{'flex-column-reverse': isList}")
      .left-block(:class="{w100: isList, 'mr_block': !isList}")
        .global-filters(v-if='getCampaign')
          filters(:selectedMarkers='getSelectedSurfaces', @updateRating="isRatingRed")
        .info-block(v-if='isRatingError > 0')
          v-icon.mr-3(color='red')
            | info
          | Найдено {{ isRatingError }} поверхностей с рейтингом ниже требуемого.
          br
          | Рекомендуем исключить их из списка инвентаря
        .info-block.filters.w225.ml-3(v-if='!isList')
          upload-csv-list.mt-4.w225(
            @updateSurfaces="surfaceUpdated"
          )
          v-select.mt-4(
            clearable,
            v-model='getCampaign.inventory',
            :items='getInventoryList || []',
            item-value='id',
            item-text='name',
            :menu-props='{ bottom: true, offsetY: true }',
            label='Выбрать готовый список',
            dense,
            outlined,
            hide-details,
            append-icon='mdi-chevron-down'
          )
        .d-flex
          v-row.pa-0.ma-0.mt-6(v-if='isList')
            span.pr-4.pl-3 Список
            v-switch.pa-0.ma-0(
              v-model='tableMode'
              color='accent',
              hide-details
            )
            span.pl-2 Выбранное
            span.pl-4 Выбрано поверхностей: {{getSelectedSurfaces.length}}
            img.pl-6.pb-2(src='@/assets/icons/excel.svg', alt='excel')
            .action_link.pl-2.pointer(@click="exportSelected") Скачать
          .d-flex.mt-6(v-if='isList')
            upload-csv-list.w200(
              @updateSurfaces="surfaceUpdated"
            )
            v-select.ml-4.mr-5(
              clearable,
              v-model='getCampaign.inventory',
              :items='getInventoryList || []',
              item-value='id',
              item-text='name',
              :menu-props='{ bottom: true, offsetY: true }',
              label='Выбрать готовый список',
              dense,
              outlined,
              hide-details,
              append-icon='mdi-chevron-down',
            )
          // Отображение элементов для карты
          span.ml-3(v-if='!isList') Выбрано поверхностей: {{getSelectedSurfaces.length}}
          img.action_icon.pl-6(src='@/assets/icons/excel.svg', v-if='!isList', alt='excel', :class="{'action-icon_map': !isList}")
          .action_link.pl-2.pointer(v-if='!isList', :class="{'action-link_map': !isList}" @click="exportSelected") Скачать
      .right-block(:class="{w100: isList}")
        .d-flex.mt-6(:class="{'justify-space-between': isList}")
          .d-flex.mr-6(:class="{'ml-2': isList}")
            .d-flex.align-center.pb-2
              v-icon.border-icons_none.mr-3(:color="isList ? '#4A55B0' : ''" @click="SET_CREATE_MODE('list')") {{isList ? 'active-list' : 'mdi-format-list-bulleted'}}
              v-icon.border-icons_none(:color="!isList ? '#4A55B0' : ''" @click="SET_CREATE_MODE('grid')") {{isList ? 'mdi-view-grid-outline' : 'active-grid'}}
          .filters
            v-btn.clear-filter-button.mb-3.mr-4(elevation='0', fab, x-small, @click='clearFilter')
              i.filter-icon
            tooltip(text='Показать только выбранные поверхности')
              v-btn.clear-filter-button.mb-3.mr-5(
                elevation='0',
                fab,
                x-small,
                :color="getMarkerMode === 'selected' ? 'accent' : ''"
                @click='setVisibleMarkers'
              )
                v-icon(size='24' :color="getMarkerMode === 'selected' ? '' : 'accent'") mdi-map-marker-check
            v-autocomplete.filter(
              v-model='city',
              :items='filteredCities || []',
              :loading='isLoading',
              :item-disabled='isDisabled',
              label='Город',
              no-data-text='Город не найден',
              color='accent',
              clearable,
              dense,
              flat,
              outlined,
              height='27',
              hide-details,
              item-text='name',
              item-value='id',
              append-icon='mdi-chevron-down'
            )
            v-select.filter(
              v-model='venue',
              :menu-props='{ bottom: true, offsetY: true }',
              :items='filteredFormats || []',
              item-text='name',
              item-value='id',
              label='Формат поверхности',
              color='accent',
              dense,
              outlined,
              :loading='isLoading',
              :item-disabled='isDisabled',
              hide-details,
              @change='getUnitsRequest'
              append-icon='mdi-chevron-down'
            )
            v-select.filter(
              v-model='type',
              :menu-props='{ bottom: true, offsetY: true }',
              :items='filteredTypes || []',
              multiple,
              label='Тип поверхности',
              color='accent',
              dense,
              outlined,
              :loading='isLoading',
              :item-disabled='isDisabled',
              hide-details,
              @change='getUnitsRequest'
              append-icon='mdi-chevron-down'
            )
            v-autocomplete.filter(
              v-model='supplier',
              :items='filteredSuppliers || []',
              :loading='isLoading',
              :item-disabled='isDisabled',
              @change='getUnitsRequest($event)',
              label='Поставщик поверхности',
              no-data-text='Поставщик не найден',
              multiple,
              color='accent',
              dense,
              flat,
              outlined,
              hide-details,
              item-text='name',
              item-value='id',
              append-icon='mdi-chevron-down'
            )
              template(v-slot:selection='{ item, index }')
                span(v-if='index === 0') {{ item.name }}
                span(v-if='index === 1') ( +{{ supplier.length - 1 }} )
            v-text-field.filter(
              v-model='gids',
              dense,
              hide-details='auto',
              outlined,
              label='Идентификатор',
              type='text'
              @change='getUnitsRequest'
            )
        #map.map-container(style='position: relative' v-show="getCreateMode === 'grid'")
          .map-items
            .poi-select
              v-select(
                v-model='poiType',
                label='Объекты на карте',
                :items='poiObjects',
                item-text='label',
                item-value='value',
                outlined,
                color='accent',
                dense,
                multiple,
                hide-details,
                :menu-props='{ maxHeight: "250" }',
                append-icon='mdi-chevron-down'
                background-color='#FFF',
                @change='onMove'
              )
                template(v-slot:selection='{ item, index }')
                  span(v-if='index === 0') {{ item.label }}
                  span(v-if='index === 1') ( +{{ poiType.length - 1 }} )
            .measure(v-if='isMeasureMode') {{ distance }}km
            i.loading(v-if='isLoading')
    surfaces.mt-6(@deleted="surfaceUpdated")
</template>

<script>
import mapboxgl from 'mapbox-gl';
import axios from 'axios'
import Vue from "vue";
import MapboxTraffic from '@mapbox/mapbox-gl-traffic';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import * as turf from '@turf/turf';
import * as XLSX from 'xlsx/xlsx.mjs';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import tooltip from '@/components/tooltip';
import surfaces from '@/components/campaignSave/surfaces';
import markerIcon from '../../assets/icons/marker.png';
import redMarkerIcon from '../../assets/icons/red-marker.png';
import activeMarkerIcon from '../../assets/icons/active-marker.png';
import filters from './filters';
import uploadCsvList from './uploadCsvList';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {GET_INVENTORY_BY_ID} from '@/store/const/inventory';
import {GET_FORECAST} from '@/store/const/report';
import {
  GET_CITIES,
  GET_PLATFORM_TYPES,
  GET_PUBLISHERS,
  GET_UNITS,
  SET_CREATE_MODE, SET_CURRENT_CITY, SET_TABLE_MODE, SET_VISIBLE_MARKERS
} from '@/store/const/map';
import loading_mixin from '@/mixins/loading_mixin';
import {
  ADD_CAMPAIGN_SURFACE,
  CLEAR_CAMPAIGN_SURFACES,
  REMOVE_CAMPAIGN_SURFACE,
  SET_SURFACES,
  SET_UNITS
} from '@/store/const/campaign';
import {center} from '@turf/turf';

export default {
  name: 'campaignSaveMap',
  components: {
    filters,
    uploadCsvList,
    surfaces,
    tooltip
  },
  mixins: [loading_mixin],
  props: {
    interface: {
      default: 'campaign'
    },
    visible: Boolean,
  },
  data() {
    return {
      format: 'grid',
      map: mapboxgl.Map,
      timeout: null,
      rating: 0,
      supplier: '',
      venue: '',
      gids: '',
      type: '',
      distance: 0,
      isMeasureMode: false,
      request: null,
      poiType: [],
      poiObjects: [
        {
          label: 'Еда',
          value: 'bar,cafe,pub,restaurant,fast_food',
          icon: 'restaurant',
        },
        {
          label: 'Здоровье',
          value:
            'health,pharmacy,hospital,veterinary,doctors,nursing_home,mortuary,clinic,ambulance_station,dentist',
          icon: 'hospital',
        },
        {
          label: 'Госучреждения',
          value:
            'democracy,administrative,town_hall,government,register_office,migration,tax_inspection,police,pension_fund,embassy,prosecutor,bailiff,social_facility,courthouse,customs,prison',
          icon: 'place-of-worship',
        },
        {
          label: 'Образование',
          value: 'education,driving_school,library,kindergarten,university,college,school',
          icon: 'college',
        },
        {
          label: 'Культура, досуг',
          value:
            'culture,library,zoo,cinema,clubs,club_automobile,club_astronomy,club_charity,club_veterans,club_game,club_history,club_cinema,club_art,club_computer,club_motorcycle,club_music,club_board_games,club_nature,club_shooting,club_hunting,club_linux,club_fishing,club_sport,club_theatre,club_tourism,club_fan,club_photography,club_chess,club_ethnic,museum,community_centre,dance,theatre,circus,arts_centre',
          icon: 'theatre',
        },
        {
          label: 'Природные объекты',
          value:
            'natural,bay,waterfall,volcano,cave_entrance,peak,cape,island,islet,beach,strait,spring,saddle',
          icon: 'picnic-site',
        },
      ],
      filteredCities: [],
      filteredSuppliers: [],
      filteredFormats: [],
      filteredTypes: [],
      firstUpdate: true,
      openedPopup: false,
    };
  },
  watch: {
    getFilteredUnits() {
      if (this.map.getSource('places') && this.map.isSourceLoaded('places')) {
        this.map.getSource('places').setData(this.geojson);
      }
      this.updateSelectMarker();
    },
    async 'getCampaign.inventory'(val) {
      await this.GET_INVENTORY_BY_ID({ id: val })

      let data = this.getInventory ? this.getInventory.surfaceIDs : {};
      this.CLEAR_CAMPAIGN_SURFACES();
      let initMapData = [];
      data.forEach((a) => {
        if (this.geojson.features.findIndex((item) => item.id === a) > -1)
          initMapData.push(this.geojson.features.find((item) => item.id === a));
      });
      initMapData.forEach((a) => {
        this.selectMarker(a.properties);
      });
    },
    getSelectedSurfaces(newVal) {
      if (newVal && newVal[0] && this.city !== newVal[0].city) {
        this.city = newVal[0].city;
      }
      this.updateSelectMarker();
    },
    async city(val) {
      await this.onCityChange();
    }
  },
  computed: {
    ...mapGetters('Inventory', ['getInventoryList', 'getInventory']),
    ...mapGetters('Report', ['getAnalytics']),
    ...mapGetters('Campaign', ['getSelectedSurfaces', 'getCampaign', 'getStrategiesMode']),
    ...mapGetters('Map', [
      'getCreateMode',
      'getUnits',
      'getFilteredUnits',
      'getCities',
      'getPublishers',
      'getFormats',
      'getPlatformTypes',
      'getSurfacesStats',
      'getTableMode',
      'getCurrentCity',
      'getRating',
      'getMarkerMode'
    ]),
    isList() {
      return this.getCreateMode === 'list'
    },
    selectedPointFilter() {
      return ['in', ['get', 'gid'], ['literal', this.getSelectedSurfaces.map((i) => i.gid)]];
    },
    redPointFilter() {
      return [
        'in',
        ['get', 'gid'],
        [
          'literal',
          this.getSelectedSurfaces.reduce((accumulated, current) => {
            const stat = this.getSurfacesStats.find(surface_stat => surface_stat.surfaceId == current.id)
            if (stat && (stat.rating < this.getRating.min || stat.rating > this.getRating.max)) {
              accumulated.push(current.gid)
            }
            return accumulated
          }, [])
        ]
      ];
    },
    isRatingError() {
      return this.getSelectedSurfaces.filter((i) => i.rating < this.rating).length;
    },
    tableMode: {
      get() {
        return this.getTableMode === 'selected'
      },
      set(val) {
        this.SET_TABLE_MODE(val ? 'selected' : 'all')
      }
    },
    city: {
      get() {
        return this.getCurrentCity
      },
      set(val) {
        console.log('tt!!!', val)
        this.SET_CURRENT_CITY(val)
      }
    },
    geojson() {
      return {
        type: 'FeatureCollection',
        features: this.getFilteredUnits.map((item) => {
          return {
            id: item.id,
            type: item.type,

            properties: {
              id: item.id,
              description: item.address,
              gid: item.gid,
              city: item.city,
              type: item.type,
              venue: item.venue,
              publisherId: item.publisherId,
              grp: item.grp,
              side: item.side,
              rating: item.rating,
              latitude: item.latitude,
              longitude: item.longitude,
              isActive: item.isActive,
              isDeleted: item.isDeleted,
              ourImageUrl: Vue.prototype.$baseURL + item.ourImageUrl.substring(1),
            },
            geometry: {
              coordinates: [item.longitude, item.latitude],
            },
          };
        }),
      };
    },
  },
  async created() {
    this.setLoadingActions();
    try {
      await this.GET_PLATFORM_TYPES();
      this.filteredTypes = this.getPlatformTypes
    } catch (error) {
      this.$notify({
        type: 'error списка типов',
        title: 'Ошибка',
        text: error.message || 'Ошибка в получении списка типов поверхностей',
      });
    }
    this.filteredFormats = this.getFormats;
    try {
      await this.GET_CITIES();
      this.filteredCities = this.getCities
    } catch (error) {
      this.$notify({
        type: 'error',
        title: 'Ошибка списка городов',
        text: error.message || 'Ошибка в получении списка городов',
      });
    }
    try {
      await this.GET_PUBLISHERS();
      this.filteredSuppliers = this.getPublishers
    } catch (error) {
      this.$notify({
        type: 'error',
        title: 'Ошибка списка паблишеров',
        text: error.message || 'Ошибка в получении списка паблишеров',
      });
    }
  },
  async mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
    try {
      await this.buildMap();
    } catch (error) {
      this.$notify({
        type: 'error',
        title: 'Ошибка отрисовки карты',
        text: error || 'Ошибка при отрисовке списка карты',
      });
    }
    if (this.city) {
      await this.onCityChange();
    }
  },
  beforeDestroy() {
    this.SET_CREATE_MODE('grid')
    this.SET_VISIBLE_MARKERS('all')
  },
  methods: {
    ...mapActions('Map', [GET_UNITS, GET_CITIES, GET_PUBLISHERS, GET_PLATFORM_TYPES]),
    ...mapActions('Report', [GET_FORECAST]),
    ...mapActions('Inventory', [GET_INVENTORY_BY_ID]),
    ...mapMutations('Campaign', [SET_SURFACES, ADD_CAMPAIGN_SURFACE, SET_UNITS, REMOVE_CAMPAIGN_SURFACE, CLEAR_CAMPAIGN_SURFACES]),
    ...mapMutations('Map', [SET_CREATE_MODE, SET_TABLE_MODE, SET_CURRENT_CITY, SET_VISIBLE_MARKERS]),
    setLoadingActions() {
      this.actions = [
        GET_UNITS,
        GET_CITIES,
        GET_PUBLISHERS,
        GET_PLATFORM_TYPES,
        GET_INVENTORY_BY_ID
      ]
    },
    isRatingRed() {
      this.map.setFilter('red-unclustered-point', this.redPointFilter)
    },
    exportSelected() {
      let sheet = XLSX.utils.json_to_sheet(this.getSelectedSurfaces);
      let book = XLSX.utils.book_new();
      book.SheetNames.push('test')
      book.Sheets['test'] = sheet
      return XLSX.writeFile(book, this.filename + ' ' + new Date().toLocaleString('ru') + '.xlsx');
    },
    surfaceUpdated() {
      this.map.setFilter('selected-unclustered-point', this.selectedPointFilter)
    },
    async updateSelectMarker() {
      if (!this.getSelectedSurfaces.length) {
        return;
      }
      let initMapData = [];
      this.getSelectedSurfaces.forEach((surface) => {
        let index = this.geojson.features.findIndex((item) => String(item.id) === String(surface.id))
        if (index > -1) {
          initMapData.push(this.geojson.features[index].properties);
        }
      });
      initMapData.forEach((a) => {
        this.selectMarker(a);
      });
    },
    async getUnitsRequest() {

      try {
        let gids = this.gids
        let locateOnMap = false
        if (gids !== '') {
          gids = gids.split(',')
          locateOnMap = gids.length === 1
        }
        let params = {
          types: this.type.length ? this.type : [],
          publisherIds: this.supplier.length ? this.supplier : [],
          cityIds: this.city ? [this.city] : [],
          venues: this.venue ? [this.venue] : [],
          gids: gids === '' ? [] : gids
        };
        if (Array.isArray(gids) && gids.length) {
          delete params.cityIds;
        }
        await this.GET_UNITS(params)
        if (!this.getUnits.length) {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: 'Поверхности по заданным параметрам не найдены',
          });
          return
        }
        if (!this.type) {
          this.filteredTypes = this.getPlatformTypes.filter((i) =>
            Object.values(this.getUnits).find((r) => r.type === i || r.type === 'undefined'),
          );
        }
        if (!this.supplier) {
          this.filteredSuppliers = this.getPublishers.filter((i) =>
            Object.values(this.getUnits).find((r) => r.publisherId === i.id),
          ).sort((a, b) => a.name.localeCompare(b.name));
        }
        if (!this.city) {
          this.filteredCities = this.getCities.filter((i) => Object.values(this.getUnits).find((r) => r.city === i.id))
            .filter(item => item.id === 'MSK' || item.id === 'SPB')
            .concat(
              this.getCities.sort((a, b) => a.name.localeCompare(b.name))
            )
        }
        if (!this.venue) {
          this.filteredFormats = this.getFormats.filter((i) => Object.values(this.getUnits).find((r) => r.venue === i.id));
        }
        if (locateOnMap && this.getUnits.length === 1) {
          this.goToMarker({
            lng: this.getUnits[0].longitude,
            lat: this.getUnits[0].latitude
          })
        }
        if (this.geojson && this.map.getSource('places')) {
          this.map.getSource('places').setData(this.geojson);
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка в получении списка',
          text: error.message || 'Ошибка в получении списка',
        });
      }
    },
    async onCityChange() {
      await this.getUnitsRequest();
      if (this.getSelectedSurfaces.length > 0 && this.firstUpdate) {
        this.goToMarker({
          lng: this.getSelectedSurfaces[0].longitude,
          lat: this.getSelectedSurfaces[0].latitude
        })
        this.firstUpdate = false;
        return;
      }
      this.map.easeTo({
        center: this.geojson.features[0] ? this.geojson.features[0].geometry.coordinates : '',
        zoom: 10,
      });
    },
    setInventorySurfaces: function () {
      this.getInventory.surfaces.map(surface => {
        let index = this.geojson.features.findIndex((item) => String(item.id) === String(surface.id))
        if (index !== -1) {
          this.selectMarker(this.geojson.features[index].properties)
        }
      });
      if (this.getSelectedSurfaces.length > 0) {
        this.goToMarker({
          lng: this.getSelectedSurfaces[0].longitude,
          lat: this.getSelectedSurfaces[0].latitude,
        })
      }
    },
    async buildMap() {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [37.61556, 55.75222],
        zoom: 7.9,
        attributionControl: false,
        pitchWithRotate: false,
        dragRotate: false,
      });
      if (this.visible) {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          marker: {
            color: '#4A55B0',
          },
          placeholder: 'Поиск',
          mapboxgl,
        });
        this.map.addControl(geocoder);
      }
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      });

      class MapboxGLButtonControl {
        constructor({ className = '', title = '', eventHandler = evtHndlr }) {
          this._className = className;
          this._title = title;
          this._eventHandler = eventHandler;
        }

        onAdd(map) {
          this._btn = document.createElement('button');
          this._btn.className = this._className;
          this._btn.type = 'button';
          this._btn.title = this._title;
          this._btn.onclick = this._eventHandler;

          this._container = document.createElement('div');
          this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl mapboxgl-custom-control';
          this._container.appendChild(this._btn);

          return this._container;
        }

        onRemove() {
          this._container.parentNode.removeChild(this._container);
          this._map = undefined;
        }
      }

      this.map.addControl(draw, 'top-right');
      this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
      this.map.on('draw.create', this.updateArea);
      this.map.on('draw.delete', this.updateArea);
      this.map.on('draw.update', this.updateArea);

      this.map.addControl(
        new MapboxGLButtonControl({
          className: 'custom-measure-control',
          title: 'Измерить расстояние',
          eventHandler: this.measureDistance,
        }),
        'top-right',
      );
      this.map.addControl(new MapboxTraffic());
      this.map.on('load', async () => {
        this.map.loadImage(markerIcon, (error, image) => {
          if (error) throw error;
          this.map.addImage('marker', image);
        });
        this.map.loadImage(activeMarkerIcon, (error, image) => {
          if (error) throw error;
          this.map.addImage('active-marker', image);
        });
        this.map.loadImage(redMarkerIcon, (error, image) => {
          if (error) throw error;
          this.map.addImage('red-marker', image);
        });
        this.map.addSource('places', {
          type: 'geojson',
          cluster: true,
          clusterMaxZoom: 11,
          clusterRadius: 50,
          data: this.geojson,
        });
        this.map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'places',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': ['step', ['get', 'point_count'], '#4A55B0', 50, '#f1f075', 100, '#f28cb1'],
            'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          },
          layout: {
            'circle-sort-key': 5,
          },
        });
        this.map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'places',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 15,
            'text-allow-overlap': true,
          },
        });
        this.map.addLayer({
          id: 'unselected-unclustered-point',
          type: 'symbol',
          source: 'places',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'marker',
            'icon-allow-overlap': true,
            'symbol-sort-key': 5,
          },
        });
        this.map.addLayer({
          id: 'selected-unclustered-point',
          type: 'symbol',
          source: 'places',
          filter: ['all', ['!', ['has', 'point_count']], this.selectedPointFilter],
          layout: {
            'icon-image': 'active-marker',
            'icon-allow-overlap': true,
            'symbol-sort-key': 5,
          },
        });
        this.map.addLayer({
          id: 'red-unclustered-point',
          type: 'symbol',
          source: 'places',
          filter: ['all', ['!', ['has', 'point_count']], this.redPointFilter],
          layout: {
            'icon-image': 'red-marker',
            'icon-allow-overlap': true,
            'symbol-sort-key': 5,
          },
        });
        await this.getUnitsRequest();
        if (this.interface === 'inventory' && this.getInventory.surfaces) {
          this.setInventorySurfaces();
        }
        await this.updateSelectMarker()
        this.map.setFilter('selected-unclustered-point', this.selectedPointFilter);
        this.map.on('click', 'clusters', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          const clusterId = features[0].properties.cluster_id;
          this.map.getSource('places').getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
        });
        this.map.on('click', 'selected-unclustered-point', (e) => {
          if (e.originalEvent.ctrlKey) {
            this.deleteMarker(e.features[0]);
          }
          this.openPopup(e);
        });
        this.map.on('click', 'unselected-unclustered-point', (e) => {
          if (e.originalEvent.ctrlKey) {
            this.selectMarker(e.features[0]);
          }
          this.openPopup(e);
        });
        this.map.on('click', 'red-unclustered-point', (e) => {
          if (e.originalEvent.ctrlKey) {
            this.deleteMarker(e.features[0]);
            this.setRating(this.rating);
          }
          this.openPopup(e);
        });

        this.map.on('mouseenter', 'clusters', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'clusters', () => {
          this.map.getCanvas().style.cursor = '';
        });
        this.map.on('mouseenter', 'unselected-unclustered-point', (e) => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'unselected-unclustered-point', () => {
          this.map.getCanvas().style.cursor = '';
        });
        this.map.on('mouseenter', 'selected-unclustered-point', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'selected-unclustered-point', () => {
          this.map.getCanvas().style.cursor = '';
        });
        this.map.on('mouseenter', 'red-unclustered-point', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'red-unclustered-point', () => {
          this.map.getCanvas().style.cursor = '';
        });
        this.map.on('click', (e) => {
          if (e.defaultPrevented === false && this.openedPopup) {
            this.openedPopup = false;
          }
        });
        await this.getPOI();
      });
    },
    openPopup(e) {
      if (this.openedPopup) {
        return;
      }
      this.openedPopup = true;
      const marker = e.features[0];
      const coordinates = marker.geometry.coordinates.slice();
      const description = marker.properties.description;
      const gid = marker.properties.gid;
      const publisher = marker.properties.publisherId;
      const venue = marker.properties.venue;
      const type = marker.properties.type;
      const grp = marker.properties.grp;
      let side = marker.properties.side;
      const isActive = marker.properties.isActive
      const isDeleted = marker.properties.isDeleted
      const ourImageUrl = marker.properties.ourImageUrl
      const previewImageUrl = marker.properties.ourImageUrl.replace('/image/', '/image/preview/')
      if (gid === '35') {
        side = 'B'
      }
      const button = `<button id="popup-button" class="popup-button v-btn v-btn--outlined v-size--small accent--text">Добавить</button>`;
      let classes = '';
      let class_description = '';
      let description_text = ""
      let gid_text = ""
      let class_gid = ""
      if (isDeleted === true) {
        classes = 'isActiveRed tooltip-top_deleted';
      } else if (isActive === false) {
        classes = 'isActiveRed tooltip-top_active';
      }
      if (description.length > 45) {
        description_text = description
        class_description = 'style_description'
      }
      if (gid.length > 45) {
        gid_text = gid
        class_gid = 'style_gid'
      }
      let src = ''
      const body = `
							<div>
                <div class="links">
                  <div><a href="https://www.google.com/" target="_blank">Ссылка на панораму</a></div>
                  <div><span id="popupImg">Фото</span></div>
                </div>
								<img id='loadImg' src="${previewImageUrl}" alt=" ">
							</div>
							<div>
							    <ul>
                    <li id="${class_description}" data-tooltip="${description_text}"><div class="${classes}"><p>Адрес: <span>${description}</span></p></div></li>
                    <li id="${class_gid}" data-tooltip="${gid_text}"><p>Идентификатор: <span>${gid}</span></p></li>
                    <li><p>Поставщик поверхности: <span>${publisher}</span></p></li>
                    <li><p>GRP: <span>${grp || 0}</span></p></li>
                    <li><p>Сторона: <span>${side || 'A'}</span></p></li>
                    <li><p>Тип: <span>${type}</span></p></li>
                  </ul>
                  <div class="container-button">${button}</div>
              </div>`
      const popup = new mapboxgl.Popup({
        focusAfterOpen: false,
        closeOnMove: true,
        maxWidth: 'max-content'
      })
        .setLngLat(coordinates)
        .setHTML(body)
        .addTo(this.map);
      const loadImg = document.getElementById('loadImg')
      if (ourImageUrl.length > 36) {
        loadImg.classList.add('loader')
      }
      loadImg.onload = () => {
        loadImg.classList.remove('loader')
      }
      const img = document.getElementById('popupImg');
      img.addEventListener('click', () => {
        this.$emit('popupImages', ourImageUrl)
        popup.remove();
        this.openedPopup = false;
      })
      img.addEventListener('mouseenter', () => {
        this.$emit('popupImages', src = '')
      })
      const btn = document.getElementById('popup-button');
      btn.addEventListener('click', () => {
        this.selectMarker(marker.properties);
        popup.remove();
        this.openedPopup = false;
      });
      this.map.on('mouseleave', 'unselected-unclustered-point', () => {
        this.map.getCanvas().style.cursor = '';
        this.$emit('popupImages', src = '')
      });
    },
    clearFilter() {
      if (this.city || this.type || this.supplier || this.venue) {
        this.city = '';
        this.type = '';
        this.supplier = '';
        this.venue = '';
        this.getUnitsRequest();
      }
      this.SET_VISIBLE_MARKERS('all');
    },
    deleteMarker(marker) {
      this.REMOVE_CAMPAIGN_SURFACE(marker);
      this.setRating(this.rating);
      this.map.setFilter('selected-unclustered-point', this.selectedPointFilter);
    },
    goToMarker(center) {
      this.map.easeTo({
        center: [center.lng, center.lat],
        zoom: 15,
      });
    },
    async selectMarker(marker) {
      if (!this.getPlatformTypes.includes(marker.type)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Тип поверхности не известен, не удалось добавить.',
        });
        return;
      }
      if (this.getSelectedSurfaces.findIndex((i) => parseInt(i.id) === parseInt(marker.id)) === -1) {
        this.ADD_CAMPAIGN_SURFACE(marker.properties ? marker.properties : marker);
        this.map.setFilter('selected-unclustered-point', this.selectedPointFilter);
      }
    },
    async getPoiFeatures() {
      const filter = this.poiType.join();
      if (this.request) this.request.cancel();
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' };
      const config = {
        params: {
          action: 'getpoibbox',
          nclass: filter,
          t: this.map.getBounds()._ne.lat,
          l: this.map.getBounds()._sw.lng,
          b: this.map.getBounds()._sw.lat,
          r: this.map.getBounds()._ne.lng,
        },
        cancelToken: axiosSource.token,
      };
      const res = await axios.get(process.env.VUE_APP_MAPBOX_BASE_URL + '/poi', config);
      return res.data && res.data.data.length && res.data.data.map((item) => {
        return {
          id: item.id,
          type: 'Feature',
          properties: {
            icon: this.poiObjects.filter((i) => i.value.indexOf(item.nclass) !== -1)[0].icon,
            name: item.name_ru || item.class_ru,
          },
          geometry: {
            type: 'Point',
            coordinates: [item.lon, item.lat],
          },
        };
      });
    },
    addPOILayers(features) {
      features.length && features.forEach((feature) => {
        const symbol = feature.properties['icon'];
        const layerID = 'poi-' + symbol;
        if (!this.map.getLayer(layerID)) {
          this.map.addLayer({
            id: layerID,
            type: 'symbol',
            source: 'poi',
            layout: {
              'icon-image': symbol + '-11',
              'text-size': 9,
              'text-field': '{name}',
              'text-offset': [0, 1.5],
              'symbol-z-order': 'source',
            },
            filter: ['==', 'icon', symbol],
          });
        }
      });
    },
    async getPOI() {
      const features = await this.getPoiFeatures();
      this.map.addSource('poi', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      });
      this.addPOILayers(features);
      this.map.on('move', async () => {
        await this.onMove();
      });
    },
    onMove() {
      this.debounce(async () => {
        const features = await this.getPoiFeatures();
        const poi = {
          type: 'FeatureCollection',
          features,
        };
        this.map.getSource('poi').setData(poi);
        this.addPOILayers(features);
      }, 800);
    },
    measureDistance() {
      if (!this.isMeasureMode) {
        const geojson = {
          type: 'FeatureCollection',
          features: [],
        };

        const linestring = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [],
          },
        };
        this.map.addSource('measure', {
          type: 'geojson',
          data: geojson,
        });

        this.map.addLayer({
          id: 'measure-points',
          type: 'circle',
          source: 'measure',
          paint: {
            'circle-radius': 5,
            'circle-color': '#000',
          },
          filter: ['in', '$type', 'Point'],
        });
        this.map.addLayer({
          id: 'measure-lines',
          type: 'line',
          source: 'measure',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#000',
            'line-width': 2.5,
          },
          filter: ['in', '$type', 'LineString'],
        });
        this.map.on('click', (e) => {
          if (this.isMeasureMode) {
            const features = this.map.queryRenderedFeatures(e.point, {
              layers: ['measure-points'],
            });

            if (geojson.features.length > 1) geojson.features.pop();

            if (features.length) {
              const id = features[0].properties.id;
              geojson.features = geojson.features.filter((point) => point.properties.id !== id);
            } else {
              const point = {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [e.lngLat.lng, e.lngLat.lat],
                },
                properties: {
                  id: String(new Date().getTime()),
                },
              };

              geojson.features.push(point);
            }

            if (geojson.features.length > 1) {
              linestring.geometry.coordinates = geojson.features.map(
                (point) => point.geometry.coordinates,
              );

              geojson.features.push(linestring);
              this.distance = turf.length(linestring).toLocaleString();
            }

            this.map.getSource('measure').setData(geojson);
          }
        });
      } else {
        this.distance = 0;
        this.map.removeLayer('measure-lines');
        this.map.removeLayer('measure-points');
        this.map.removeSource('measure');
      }
      this.isMeasureMode = !this.isMeasureMode;
    },
    debounce: function (callback, time) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        callback();
      }, time);
    },
    updateArea(data) {
      const polygon = data.features[0];
      this.geojson.features.forEach((feature) => {
        if (turf.booleanPointInPolygon(feature.geometry.coordinates, polygon)) {
          this.selectMarker(feature);
        }
      });
    },
    isDisabled() {
      return !!this.isLoading;
    },
    setVisibleMarkers() {
      if (this.getSelectedSurfaces.length > 0 && this.getMarkerMode === 'all') {
        this.SET_VISIBLE_MARKERS('selected')
      } else if (this.getMarkerMode === 'selected') {
        this.SET_VISIBLE_MARKERS('all')
      } else {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Нет выбранных поверхностей',
        });
      }
    }
  },
};
</script>

<style lang="scss">
@import 'https://api.mapbox.com/mapbox-gl-js/v2.0.0/mapbox-gl.css';
@import 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.css';
@import 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.2.0/mapbox-gl-draw.css';

.map-container {
  width: 100%;
  height: 35vh;
  margin-top: 20px;
}

.map-block {
  display: flex;
  max-height: 80vh;
  overflow-y: hidden;
  margin-bottom: 30px;
}

.w100 {
  width: 100% !important;
}

.w200 {
  width: 200px !important;
}

.w225 {
  width: 225px !important;
}

.mapboxgl-popup-content {
  box-shadow: 1px 3px 10px 3px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 10px 15px 11px 9px;
  width: 542px;
  height: 185px;
  display: flex;
  flex-direction: row;

  & li {
    list-style: none;
  }

  & p {
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    margin: 0 0 5px;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & span {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  }

  & .links {
    margin-bottom: 5px;
    display: flex;
    flex-wrap: nowrap;
  }

  & .links div {
    padding: 2px 3px 1px 4px;
    border: 0.3px solid #4A55B0;
    border-radius: 2px;
  }

  & .links div:first-child {
    margin-right: 8px;
  }

  & ul {
    padding: 19px 0 0 8px;
  }

  & a, & .links span {
    height: 21px;
    width: 100%;
    color: #4A55B0;
    font-size: 12px;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    display: block;
  }

  & img {
    width: 178px;
    height: 135px;
    border-radius: 3px;
    display: block;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.mapboxgl-popup-close-button {
  font-size: 15px;
  border: 0.3px solid #4A55B0;
  border-radius: 50%;
  color: #000000;
  margin: 9px 6px;
  padding: 0 6px;
}

.left-block {
  width: 21%;
  overflow-y: auto;
}

.mr_block {
  margin-right: 10px;
}

.right-block {
  flex-direction: column;
  margin-right: auto;
  width: 80%;
}

.action_link {
  color: #4A55B0;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;

  & .filter {
    margin-right: 13px;
    max-width: 165px;
  }
}

.map-items {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 60px;
  display: flex;
}

.poi-select {
  width: 200px !important;
}

.measure {
  margin-left: 10px;
  padding: 7px 10px 6px;
  border: 1px solid #dcdfe7;
  border-radius: 4px;
  background: #fff;
  color: #737373;
}

.clear-filter-button {
  border: 1px solid #4a55b0;
  margin-top: 3px;
  border-radius: 50% !important;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 30%) !important;
}

.filter-icon {
  background: url('~@/assets/icons/filter-icon.svg') no-repeat;
  background-size: contain;
  padding: 8px;
  display: block;
}

.container-button {
  position: relative;
  top: -33px;
  left: 0;
  display: flex;
  justify-content: flex-end;
}

.info-block {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 1.1;
  margin-bottom: 40px;
}

.mapboxgl-custom-control {
  border-radius: 50%;
  background: #4a55b0;

  & > .custom-measure-control {
    border-radius: 50%;
    background: url('~@/assets/icons/ruler-icon.svg') 50% no-repeat;
  }

  & + .mapboxgl-ctrl {
    border-radius: 50%;
    background: #4a55b0;

    & .mapboxgl-ctrl-icon,
    .mapboxgl-ctrl-traffic {
      border-radius: 50%;
      background: url('~@/assets/icons/traffic-icon.svg') 50% no-repeat;
    }
  }
}

.mapboxgl-ctrl-group.mapboxgl-ctrl {
  position: relative;
  display: flex;
  box-shadow: none;
  border: 1px solid #dcdfe7;
  border-radius: 50%;

  & .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon {
    border-radius: 50%;
    z-index: 99;
    background: #4a55b0 url('~@/assets/icons/constellation-with-six-stars.svg') 50% no-repeat;
    background-size: 18px;
  }

  & .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash {
    position: absolute;
    z-index: 1;
    width: 35px;
    height: 20px;
    right: 25px;
    top: 5px;
    border: 0.5px solid rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    background: #FFFFFF url('~@/assets/icons/trash-icon.svg') 50% no-repeat;
    background-size: 12px;
  }
}

.mapboxgl-ctrl-geocoder {
  width: 25%;
  min-width: 220px;
  font-size: 15px;

  &--input {
    height: 36px;
    padding: 6px 35px;
  }

  &--icon-search {
    left: 7px;
    width: 20px;
    height: 20px;
    top: 8px;
  }
}

.loading {
  z-index: 1;
  bottom: 5px;
  left: 10px;
}

@media screen and (min-width: 1920px) {
  .map-container {
    height: 50vh;
  }
}

@media screen and (max-width: 1919px) {
  .left-block {
    width: 23%;
  }
  .action-link_map {
    padding-top: 22px;
  }
  .action-icon_map {
    padding-top: 20px;
  }
  .map-container {
    height: 60vh;
  }
  .filters {
    & .filter {
      max-width: 200px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .left-block {
    width: 24%;
  }
  .map-container {
    height: 60vh;
  }
  .action-link_map {
    padding-right: 5px;
  }
  .right-block {
    width: 70%;
  }
}

@media screen and (max-width: 1050px) {
  .map-container {
    height: 55vh;
  }
  .map-block {
    flex-direction: column-reverse;
    height: 100%;
    max-height: 110vh;
    margin-right: 0;
  }
  .right-block {
    width: 100%;
    margin: 0 auto;
  }
  .left-block {
    width: 90%;
    margin: 20px auto;
  }
  .info-block {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 560px) {
  .left-block {
    width: 100%;
    margin-top: 50px;
  }
  .poi-select {
    top: 45px;
  }
  .mapboxgl-popup-content {
    padding: 10px 15px 0 9px;
  }
  .mapboxgl-popup-close-button {
    font-size: 13px;
    margin: 5px 5px;
    padding: 0 6px;
  }
}

@media screen and (max-height: 800px) {
  .mapboxgl-popup-content {
    padding: 10px 15px 0 9px;
  }
}

.v-application .accent--text {
  color: #4A55B0 !important;
  caret-color: #4A55B0 !important;
}

.v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  position: absolute;
  transition: inherit;
  width: 30px;
  left: -12px;
  top: calc(50% - 24px);
  margin: 7px;
}

.mdi-checkbox-blank-circle-outline::before {
  color: #4A55B0;
}

.border-icons_none.v-icon:after {
  background: none;
}

.container-flex {
  display: flex;
  justify-content: space-between;
}

.isActiveRed {
  display: flex;
  flex-direction: row-reverse;
}

.isActiveRed::before {
  content: '';
  width: 12px;
  height: 12px;
  display: block;
  border: 2px solid red;
  margin-top: 5px;
  background: red;
  border-radius: 50%;
  position: relative;
  bottom: 20px;
  right: 20px;
}

.tooltip-top_active {
  position: relative;
  cursor: pointer;
}

.tooltip-top_active::after {
  content: 'временно не активна';
  position: absolute;
  min-width: 150px;
  right: -40px;
  bottom: calc(100% + 25px);
  background-color: #555;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  visibility: hidden;
}

.tooltip-top_active:hover::after {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s cubic-bezier(.4, 0, .6, 1);
}

.tooltip-top_deleted {
  position: relative;
  cursor: pointer;
}

.tooltip-top_deleted::after {
  content: 'долго не активна';
  position: absolute;
  min-width: 150px;
  right: -40px;
  bottom: calc(100% + 25px);
  background-color: #555;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  visibility: hidden;
}

.tooltip-top_deleted:hover::after {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s cubic-bezier(.4, 0, .6, 1);
}

.active-grid {
  background: url('~@/assets/icons/active-border-grid.svg') no-repeat;
  background-size: contain;
  width: 28px;
  height: 22px;
  display: block;
}

.active-list {
  background: url('~@/assets/icons/active-border-list.svg') no-repeat;
  background-size: contain;
  width: 28px;
  height: 22px;
  display: block;
}

#style_description {
  position: relative;
  cursor: pointer;
}

#style_description::after {
  content: attr(data-tooltip);
  position: absolute;
  width: 310px;
  left: 0;
  top: 0;
  background: #3989c9;
  color: #fff;
  padding: 0.5em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  opacity: 0;
  transition: 1s;
}

#style_description:hover::after {
  opacity: 1;
  top: -55px;
}

#style_gid {
  position: relative;
  cursor: pointer;
}

#style_gid::after {
  content: attr(data-tooltip);
  position: absolute;
  width: 310px;
  left: 0;
  top: 0;
  background: #3989c9;
  color: #fff;
  padding: 0.5em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  opacity: 0;
  transition: 1s;
}

#style_gid:hover::after {
  opacity: 1;
  top: -55px;
}
.loader {
  position: absolute;
  left: 35px;
  top: 60px;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  border: 16px solid  #f3f3f3;
  border-top: 16px solid #4A55B0;
  transform: translate(-50%, -50%);
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  from{
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}
</style>
