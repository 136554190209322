<template lang="pug">
  v-container(fluid)
    v-row(align='center', justify='end')
      .center-block.mb-2.mr-3
        v-text-field.ml-4(
          style='width: 350px',
          label='Название списка',
          v-model='inventory.name',
          dense,
          outlined,
          hide-details='auto'
        )
        v-btn.ml-4(elevation='0', color='accent', @click='save') {{ $route.params.id ? 'Сохранить список' : 'Создать список' }}
    campaign-save-map(interface='inventory')
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import campaignSaveMap from '@/components/campaignSave/map';
import loading_mixin from '@/mixins/loading_mixin';
import {CREATE_INVENTORY, UPDATE_INVENTORY} from '@/store/const/inventory';
import {CLEAR_CAMPAIGN_SURFACES} from '@/store/const/campaign';
import {SET_CURRENT_CITY} from '@/store/const/map';

export default {
  name: 'InventoryMapWrapper',
  components: {
    campaignSaveMap,
  },
  mixins: [loading_mixin],
  data() {
    return {
      inventory: {
        name: '',
      },
    }
  },
  mounted() {
    this.inventory = this.getInventory;
  },
  watch: {
    getInventory() {
      this.inventory = this.getInventory;
    }
  },
  computed: {
    ...mapGetters('Campaign', ['getSelectedSurfaces']),
    ...mapGetters('Inventory', ['getInventory']),
  },
  beforeDestroy() {
    this.CLEAR_CAMPAIGN_SURFACES();
    this.inventory = {
      name: '',
    };
    this.SET_CURRENT_CITY('MSK')
  },
  methods: {
    ...mapMutations('Campaign', [CLEAR_CAMPAIGN_SURFACES]),
    ...mapMutations('Map', [SET_CURRENT_CITY]),
    ...mapActions('Inventory', [UPDATE_INVENTORY, CREATE_INVENTORY]),
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
    valid() {
      if (!this.inventory.name) {
        this.errorShow('Введите название списка');
        return false;
      } else if (this.inventory.name.length < 3) {
        this.errorShow('Название списка дожно быть не менее 3-х символов');
        return false;
      }
      if (!this.getSelectedSurfaces.length) {
        this.errorShow('Вы не выбрали инвентарь');
        return false;
      }

      return true;
    },
    async save() {
      if (!this.valid()) return;
      let data = {
        name: this.inventory.name,
        surfaceIDs: this.getSelectedSurfaces.map(a => String(a.id)),
      };
      if (this.$route.params.id) {
        data.id = this.$route.params.id;
        await this.UPDATE_INVENTORY(data)
        if (this.isError(UPDATE_INVENTORY)) {
          this.errorShow(this.errorMessage(UPDATE_INVENTORY));
          return;
        }
        this.$notify({
          type: 'success',
          title: '',
          text: 'Список успешно изменен',
        });
      } else {
        await this.CREATE_INVENTORY(data)
        if (this.isError(CREATE_INVENTORY)) {
          this.errorShow(this.errorMessage(CREATE_INVENTORY));
          return;
        }
        this.$notify({
          type: 'success',
          title: '',
          text: 'Список успешно создан',
        });
      }
      await this.$router.push('/radar/inventory/');
    },
  },
};
</script>

<style scoped></style>
